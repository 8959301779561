import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from '../Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text4};
`

export default function CurrencyLogo({
  address,
  size = '24px',
  style,
  ...rest
}: {
  address?: string
  size?: string
  style?: React.CSSProperties
}) {
  const [srcs, setSrcs] = useState<string[]>([])
  useEffect(() => {
    fetch(process.env.REACT_APP_ASSETS_URL as string)
      .then((res) => res.json())
      .then((json) => json.tokens)
      .then((tokens) => tokens.filter((token: any) => token.address.toLowerCase() === address?.toLowerCase()))
      .then((token) => setSrcs([token[0]?.logoURI]))
  }, [address])

  return <StyledLogo size={size} srcs={srcs} alt={'token logo'} style={style} {...rest} />
}
